<template>
  <layout-header></layout-header>
  <layout-sidebar></layout-sidebar>
  <div class="page-wrapper">
    <div class="content settings-content">
      <div class="page-header settings-pg-header">
        <div class="add-item d-flex">
          <div class="page-title">
            <h4>Settings</h4>
            <h6>Manage your settings on portal</h6>
          </div>
        </div>
        <ul class="table-top-head">
          <li>
            <a data-bs-toggle="tooltip" data-bs-placement="top" title="Refresh"
              ><i data-feather="rotate-ccw" class="feather-rotate-ccw"></i
            ></a>
          </li>
          <li>
            <a
              ref="collapseHeader"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Collapse"
              @click="toggleCollapse"
            >
              <i data-feather="chevron-up" class="feather-chevron-up"></i>
            </a>
          </li>
        </ul>
      </div>
      <div class="row">
        <div class="col-xl-12">
          <div class="settings-wrapper d-flex">
            <div class="sidebars settings-sidebar theiaStickySidebar" id="sidebar2">
              <settings-sidebar></settings-sidebar>
            </div>
            <div class="settings-page-wrap w-50">
              <div class="setting-title">
                <h4>Language</h4>
              </div>
              <div class="page-header">
                <div class="back-btn">
                  <router-link to="language-settings" class="btn btn-translation"
                    ><vue-feather type="arrow-left" class="filter-icon me-2"></vue-feather
                    >Back to Translations
                  </router-link>
                </div>
                <div class="page-btn">
                  <a
                    href="javascript:void(0);"
                    class="d-flex align-items-center selected-language"
                    ><img
                      src="@/assets/img/icons/flag-02.svg"
                      class="me-2"
                      alt=""
                    />Arabic</a
                  >
                </div>
              </div>
              <div class="row">
                <div class="col-lg-12">
                  <div class="card table-list-card">
                    <div class="card-body">
                      <div class="table-top">
                        <div class="search-set">
                          <div class="search-input">
                            <input type="text" placeholder="Search" class="dark-input" />
                            <a href="javascript:void(0);" class="btn btn-searchset"
                              ><i data-feather="search" class="feather-search"></i
                            ></a>
                          </div>
                        </div>
                      </div>
                      <div class="table-responsive">
                        <a-table
                          class="table datanew"
                          :columns="columns"
                          :data-source="data"
                          :row-selection="{}"
                        >
                          <template #bodyCell="{ column, record }">
                            <template v-if="column.key === 'File'">
                              <span class="file-data">{{ record.File }}</span>
                            </template>
                            <template v-else-if="column.key === 'Progress'">
                              <div class="position-relative">
                                <div class="progress attendance language-progress">
                                  <div :class="record.ProgressClass" role="progressbar">
                                    <span>{{ record.Progress }}</span>
                                  </div>
                                </div>
                              </div>
                            </template>
                            <template v-else-if="column.key === 'action'">
                              <td class="action-table-data">
                                <div class="edit-delete-action">
                                  <a
                                    class="me-2 p-2"
                                    href="javascript:void(0);"
                                    data-bs-toggle="offcanvas"
                                    data-bs-target="#offcanvasRight"
                                  >
                                    <i data-feather="edit" class="feather-edit"></i>
                                  </a>
                                  <a
                                    class="confirm-text p-2"
                                    @click="showConfirmation"
                                    href="javascript:void(0);"
                                  >
                                    <i data-feather="trash-2" class="feather-trash-2"></i>
                                  </a>
                                </div>
                              </td>
                            </template>
                          </template>
                        </a-table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <language-settings-web-modal></language-settings-web-modal>
</template>
<script>
const columns = [
  {
    title: "Medium",
    dataIndex: "Medium",
    key: "Medium",
    sorter: {
      compare: (a, b) => {
        a = a.Medium.toLowerCase();
        b = b.Medium.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "File",
    dataIndex: "File",
    key: "File",
    sorter: {
      compare: (a, b) => {
        a = a.File.toLowerCase();
        b = b.File.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Total",
    dataIndex: "Total",
    sorter: {
      compare: (a, b) => {
        a = a.Total.toLowerCase();
        b = b.Total.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Complete",
    dataIndex: "Complete",
    sorter: {
      compare: (a, b) => {
        a = a.Complete.toLowerCase();
        b = b.Complete.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Progress",
    dataIndex: "Progress",
    key: "Progress",
    sorter: {
      compare: (a, b) => {
        a = a.Progress.toLowerCase();
        b = b.Progress.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Action",
    key: "action",
    sorter: true,
  },
];
const data = [
  {
    Language: "English",
    Progress: "80%",
    Image: "flag-01.svg",
    Medium: "Web",
    Total: "2145",
    Complete: "1815",
    File: "Inventory",
    ProgressClass: "progress-bar progress-bar-warning",
  },
  {
    Language: "Arabic",
    Progress: "100%",
    Image: "flag-02.svg",
    Medium: "Web",
    Total: "2045",
    Complete: "2045",
    File: "Expense",
    ProgressClass: "progress-bar progress-bar-success",
  },
  {
    Language: "Chinese",
    Progress: "5%",
    Image: "flag-03.svg",
    Medium: "Web",
    Total: "2245",
    Complete: "295",
    File: "Product",
    ProgressClass: "progress-bar progress-bar-violet",
  },
  {
    Language: "Hindi",
    Progress: "40%",
    Image: "flag-04.svg",
    Medium: "Web",
    Total: "2535",
    Complete: "1145",
    File: "Settings",
    ProgressClass: "progress-bar progress-bar-violet-two",
  },
];
export default {
  data() {
    return {
      Selectlang: ["Select Language", "English", "Chinese"],
      data,
      columns,
    };
  },
  methods: {
    showConfirmation() {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          Swal.fire({
            icon: "success",
            title: "Deleted!",
            text: "Your file has been deleted.",
            confirmButtonClass: "btn btn-success",
          });
        }
      });
    },
    toggleCollapse() {
      const collapseHeader = this.$refs.collapseHeader;

      if (collapseHeader) {
        collapseHeader.classList.toggle("active");
        document.body.classList.toggle("header-collapse");
      }
    },
  },
};
</script>
